import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idpresupuestocli) {
    const apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectUbiLpresupuestocli (Vue, filter, search, sorter, page, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.ubiLpresupuestocli.searchFilter(search))
    }
    if (filter.ubicacion.value) {
      apiFilter.addILike(filter.ubicacion.field, filter.ubicacion.value)
    }
    const resp = await Vue.$api.call('ubiLpresupuestocli.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectUbiLpresupuestocliRows (Vue, pks) {
    const apiFilter = new APIFilter()
      .addIn('idubi_lpresupuestocli', pks)
    const resp = await Vue.$api.call('ubiLpresupuestocli.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
