<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar|uppercase }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
    <b10-list-item-right-avatar
      v-if="!formattedItem.ubicacion"
      color="error"
    >
      sin ubicación
    </b10-list-item-right-avatar>
  </b10-list-item>
</template>

<script>
import { stringToHslColor } from '@/utils/ui'
import _ from '@/utils/lodash'
import { calculateMd5 } from '@/utils/crypto'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.ubiLpresupuestocli.title(item)
      item.subtitle = this.$online.ubiLpresupuestocli.subtitle(item)
      item.avatar = this.$options.filters.initials(item.descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.codigo))
      return item
    },
  },
}
</script>
